import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Modal, Select, Space, Spin, Tooltip, Typography, Tabs, TimePicker } from 'antd';
import { createPortal } from 'react-dom';
import { i18n } from '@/core';
import { OrderFeePanel } from '@components/OrderFeePanel';
import { Products, DNDConfirantion, UnisignedProducts } from './components';
import { mergeData } from '@/shared/components/modal/DNDProductDeliveryConfirmation';
import { driversQuery, plantsQuery, trucksQuery } from '@/view/modals/order/ChangeOrderTrucksModal/query';
import { TruckItem } from '@/view/modals/order/ChangeOrderTrucksModal/TruckItem';
import { AsyncQuerySelect } from '@/shared/components/dataEntry/AsyncQuerySelect';
import ICONS from '@/assets/images/icons';
import { DeleteFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { buildIdInput } from '@/shared/api/brokrete/helper';
import { array, isEmpty, number } from '@/libs';
import { omitBy, pick } from 'lodash';
import { useIsMounted } from '@/view/hooks/useIsMounted';
import { useDropdownButtonMenu, DropdownKeysEnum } from '@/view/modals/order/utils/dropdownButtonMenu';
import { DropdownButton } from '@/shared/components/navigation/DropdownButton';
import useTimeBetweenTrucksOptions from '@/view/hooks/useTimeBetweenTrucksOptions';
import { useGettext } from '@cranium/i18n';
import { makeIdInput } from '@/view/utils';
import { OrderQuery, useOrderQuery } from './queries/orderWithConfigQuery.generated';
import { Delivery } from './components/DeliveryItems';
import moment, { Moment } from 'moment';
import {
  useChangeOrderTrucksModalOrderUpdateMutation,
  ChangeOrderTrucksModalOrderUpdateMutation
} from './queries/updateOrder.generated';
import { useGetClientSettings } from '@/shared/access';

type DeliveryItems = OrderQuery['order']['trucksDrivers'][0]['deliveryItems'];

const FormRow = styled.div<{ width?: string }>`
  display: grid;
  grid-template-columns: ${({ width }) => width || '150px'} 1fr;
  gap: 20px;
`;

const DriverTruckContainer = styled.div`
  display: grid;
  grid-template-columns: auto 150px 150px 150px 50px;
  gap: 12px;
  .ant-form-item-control-input-content {
    align-self: stretch;
  }
`;

const getInputProps = (form: FormState): InputProps => {
  const trucks = form.trucks?.map(v => v.value);
  const quantity = trucks ? array.sum(trucks) : undefined;
  const fixedQuantity = quantity ? number.round(quantity, { math: true, factor: 4 }) : undefined;
  const deliveries = form.trucks?.map(v => ({ id: v.truckId ?? '' }));
  return omitBy(
    {
      deliveryMethod: form.deliveryMethod,
      trucks,
      timeBetweenTrucks: form.timeBetweenTrucks,
      drivers: form.trucks.map(truck => ({ id: truck.driver?.value ?? '' })),
      deliveryTrucks: form.trucks.map(truck => ({ id: truck.truck?.value ?? '' })),
      deliveryPlants: form.trucks.map(truck => ({ id: truck.plant?.value ?? '' })),
      deliveries,
      quantity: fixedQuantity
    },
    v => v === undefined
  );
};

type ProductType = {
  productTypePrice: {
    productType: NonNullable<NonNullable<OrderQuery['order']['prices'][0]>['productType']>;
    price: NonNullable<OrderQuery['order']['prices'][0]>['price'];
  };
  quantity?: number | null;
  value: number;
  productDecoratePrice?: {
    productDecorate: NonNullable<OrderQuery['order']['prices'][0]['productDecorate']>;
    price: NonNullable<OrderQuery['order']['prices'][0]>['price'];
  };
};
function extractProductPrices(order: OrderQuery['order']): Array<ProductType> {
  const helper: { [id: string]: ProductType } = {};

  return order.prices
    .filter(value => value.productType != null || value.productDecorate != null)
    .sort((a, b) => {
      const keyA = a.productType != null ? 1 : 0;
      const keyB = b.productType != null ? 1 : 0;

      return keyB - keyA;
    })
    .reduce<ProductType[]>((result, value) => {
      if (value.productType != null) {
        const productPrice: ProductType = {
          productTypePrice: {
            productType: value.productType,
            price: value.price
          },
          quantity: isEmpty(value.quantity) ? undefined : value.quantity,
          value: value.value
        };
        if (value.id) {
          helper[value.id] = productPrice;
        }

        return [...result, productPrice];
      } else if (value.productDecorate != null) {
        if (value.anchorPrice != null) {
          const anchoredPrice = value.anchorPrice.id ? helper[value.anchorPrice.id] : null;
          if (anchoredPrice != null) {
            anchoredPrice.productDecoratePrice = {
              productDecorate: value.productDecorate,
              price: value.price
            };
          }
        }
      }

      return result;
    }, []);
}

function buildProductInput(order: OrderQuery['order']) {
  const productPrices = extractProductPrices(order);
  return {
    ...buildIdInput(order.product),
    prices: productPrices.map(value => ({
      type: buildIdInput(value?.productTypePrice?.productType),
      typePrice: buildIdInput(value.productTypePrice.price),
      ...(value.productDecoratePrice && {
        decorate: buildIdInput(value.productDecoratePrice.productDecorate),
        decoratePrice: buildIdInput(value.productDecoratePrice.price)
      }),
      ...(!isEmpty(value.quantity) && {
        quantity: value.quantity
      })
    }))
  };
}

interface InputProps {
  deliveryMethod?: string;
  trucks?: Array<number>;
  deliveryPlants?: Array<{ id: string }>;
  deliveryTrucks?: Array<{ id: string }>;
  timeBetweenTrucks?: number;
  drivers?: Array<{ id: string }>;
  deliveries?: Array<{ id: string }>;
  quantity?: number;
}

type Driver = NonNullable<OrderQuery['order']['trucksDrivers'][0]['driver']>;
type Truck = NonNullable<OrderQuery['order']['trucksDrivers'][0]['truck']>;
type Plant = NonNullable<OrderQuery['order']['plant']>;
interface FormState {
  deliveryMethod?: string;
  timeBetweenTrucks?: number;
  trucks: Array<{
    value: number;
    driver?: Driver & { value: string; label: string };
    truck?: Truck & { value: string; label: string };
    plant?: Plant & { value: string; label: string };
    truckId?: string;
    deliveryItems?: DeliveryItems;
    deliveryTime?: Moment | null;
  }>;
  unisignedProducts?: DeliveryItems;
}

interface Props {
  onChange: (order?: ChangeOrderTrucksModalOrderUpdateMutation['orderUpdate']['order'] | null) => void;
  orderId: string;
}

export const ChangeOrderTrucksModal: React.FunctionComponent<Props> = ({ onChange, orderId }) => {
  const dropdownButtonMenu = useDropdownButtonMenu();
  const isMounted = useIsMounted();
  const [form] = Form.useForm<FormState>();
  const [feesLoading, setFeesLoading] = useState<boolean>(false);
  const [truckConfigView, setTruckConfigView] = useState<'general' | 'product'>('general');
  const [inputProps, setInputProps] = useState<InputProps>({});
  const [driverInputKey, setDriverInputKey] = useState('defaultKey');
  const feeInstance = OrderFeePanel.useInstance();
  const [touched, setTouched] = useState(false);
  const { data, loading } = useOrderQuery({
    variables: { id: orderId },
    fetchPolicy: 'no-cache'
  });
  const possibleDeliveryDriverRoles = useGetClientSettings('driver_tasks.possible_driver_roles.delivery');
  const possibleDeliveryTruckRoles = useGetClientSettings('driver_tasks.possible_truck_roles.delivery');

  const order = data?.order as OrderQuery['order'];
  const orderPreparatoryConfig = data?.orderPreparatoryConfig;

  const [updateOrder, { loading: loadingMutation }] = useChangeOrderTrucksModalOrderUpdateMutation();
  const canSetupTrucks = order?.product.permissions.trucks;
  const industry = order?.product.industry;
  const isMultyProduct = industry === 'related_products';
  const { options: timeBetweenTrucksOptions } = useTimeBetweenTrucksOptions({
    industry
  } as any);
  const { gettext } = useGettext();
  const isQuote = order?.status === 'quote';
  const trucksField: FormState['trucks'] = Form.useWatch('trucks', form);

  const orderPlantItem = useMemo(() => {
    if (order) {
      return { ...order.plant, value: order.plant.id, label: order.plant.name };
    }
    return undefined;
  }, [order]);

  const getSelectedPlantId = useCallback(
    (id: number) => {
      return inputProps.deliveryPlants?.find((plant, index) => id === index)?.id ?? orderPlantItem?.id;
    },
    [inputProps.deliveryPlants, orderPlantItem]
  );

  useEffect(() => {
    const getDriverItem = (driver?: Driver | null) =>
      driver ? { ...driver, value: driver.id, label: driver.info.name } : undefined;
    const getTruckItem = (truck?: Truck | null) => (truck ? { ...truck, value: truck.id, label: truck.name } : undefined);
    const getPlantItem = (plant?: Plant) => (plant ? { ...plant, value: plant.id, label: plant.name } : undefined);
    if (order) {
      form.setFieldsValue({
        deliveryMethod: order.deliveryMethod.name,
        timeBetweenTrucks: order.timeBetweenTrucks,
        trucks: order.trucksDrivers.map((item, index) => {
          const value = item?.deliveryItems?.reduce((res, item) => {
            if (item?.productType?.measuredAs === 'secondary') return res;
            return res + item.quantity;
          }, 0);
          return {
            value,
            driver: item.driver ? { ...item.driver, label: item.driver.info.name || '', value: item.driver.id } : undefined,
            truck: item.truck ? { ...item.truck, label: item.truck.name || '', value: item.truck.id } : undefined,
            plant: item?.plant ? { ...item.plant, label: item.plant.name || '', value: item.plant.id } : undefined,
            truckId: item.truckId,
            deliveryItems: item.deliveryItems,
            deliveryTime: item.deliveryTime ? moment.tz(item.deliveryTime, order?.timezone as string) : undefined
          };
        }),
        unisignedProducts: []
      });
      setInputProps(getInputProps(form.getFieldsValue()));
    }
  }, [order]);

  const feeInput = useMemo(() => {
    const productTypeID = order?.prices.find(price => price?.productType?.id)?.productType?.id;
    const deliveryItems = Array.isArray(trucksField)
      ? trucksField.map(({ deliveryItems, value }) => {
          if (!isMultyProduct) {
            return [
              {
                productType: { id: productTypeID },
                quantity: value
              }
            ];
          }
          return deliveryItems?.map(item => ({
            productType: { id: item.productType.id },
            quantity: item.quantity
          }));
        })
      : undefined;
    return {
      ...pick(inputProps, ['deliveryMethod', 'timeBetweenTrucks']),
      deliveryItems
    };
  }, [inputProps, trucksField, order, isMultyProduct]);

  const onTruckSelect = (option: any, index: number) => {
    const { data }: { data: Truck } = option ?? {};
    const existingFormDriver = form.getFieldValue(['trucks', index, 'driver']);
    if (data?.driver?.id && data.driver?.info?.name && !existingFormDriver) {
      setDriverInputKey(data.driver.id);
      form.setFields([{ name: ['trucks', index, 'driver'], value: { value: data.driver.id, label: data.driver.info.name } }]);
    }
  };

  const onDriverSelect = (option: any, index: number) => {
    const { data }: { data: Driver } = option ?? {};
    const defaultTruck = data?.trucks[0] ?? {};
    const existingTruck = form.getFieldValue(['trucks', index, 'truck']);
    if (defaultTruck?.id && defaultTruck?.name && !existingTruck) {
      form.setFields([{ name: ['trucks', index, 'truck'], value: { value: defaultTruck.id, label: defaultTruck.name } }]);
    }
  };

  return (
    <Modal
      title={<Typography className="semibold-16">{i18n.t('labels.schedule')}</Typography>}
      open={true}
      onCancel={() => onChange()}
      width={958}
      footer={
        <Space className="d-flex w-100 justify-content-between">
          <OrderFeePanel.TotalPrice feePanelInstance={feeInstance} />
          <DropdownButton
            dropdownMenu={dropdownButtonMenu}
            loading={loading || loadingMutation || feesLoading}
            disabled={!touched}
            onClick={async option => {
              if (feeInstance.requireActionConfirmation()) {
                return feeInstance.confirmAction();
              } else {
                form.getFieldValue('unisignedProducts');
                const unassigned = form.getFieldValue('unisignedProducts');
                if (Array.isArray(unassigned) && unassigned.find(item => item.quantity > 0)) {
                  setTruckConfigView('product');
                  await form.validateFields();
                }
                const values = form.getFieldsValue();
                const { quantity, trucks, ...input } = getInputProps(values);
                const product = buildProductInput(order);
                const isReadyMix = industry !== 'related_products' && industry !== 'service' && industry !== 'concrete_pumping';
                if (isReadyMix) {
                  product.prices[0].quantity = quantity;
                }

                const productType = order?.prices?.find(item => item?.productType?.id)?.productType?.id;
                const deliveryItems = values.trucks.map(({ deliveryItems, value }) => {
                  return (
                    deliveryItems
                      ? deliveryItems
                      : isReadyMix && productType
                      ? [{ productType: { id: productType || '' }, quantity: 0 }]
                      : []
                  )
                    .map(item => ({
                      productType: { id: item?.productType?.id },
                      quantity: isReadyMix ? value : item?.quantity
                    }))
                    .filter(({ quantity }) => !!quantity);
                });

                const deliveryTimes = values.trucks.map(({ deliveryTime }) => {
                  return deliveryTime ? deliveryTime.format('YYYY-MM-DD HH:mm:ssZ') : null;
                });

                const orderVariables = {
                  id: orderId,
                  product,
                  ...input,
                  fees: feeInstance.getFee(),
                  deliveryItems: deliveryItems,
                  deliveryTimes: deliveryTimes
                };
                const variables = {
                  order: orderVariables,
                  quiet: option === DropdownKeysEnum.withoutNotification,
                  askToConfirm: option === DropdownKeysEnum.askApprove
                };
                const { data } = await updateOrder({ variables });
                if (data?.orderUpdate.success && isMounted.current) {
                  onChange(data.orderUpdate.order);
                }
              }
            }}
          />
        </Space>
      }
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onValuesChange={(_, values) => {
            setTouched(true);
            setInputProps(getInputProps(values));
          }}
        >
          <DNDConfirantion setTouched={setTouched}>
            <FormRow>
              <div style={isMultyProduct ? { display: 'none' } : undefined}>
                <Form.Item
                  label={
                    <Typography.Text className="normal-12 text-uppercase gray-6">{gettext('DELIVERY METHOD')}</Typography.Text>
                  }
                  name={'deliveryMethod'}
                  normalize={item => item?.value}
                >
                  <Select
                    disabled={!canSetupTrucks}
                    labelInValue
                    options={orderPreparatoryConfig?.trucksConfigs.map(({ deliveryMethod: { name } }) => ({
                      value: name,
                      label: name
                    }))}
                  />
                </Form.Item>
              </div>
              <DriverTruckContainer>
                <Form.Item
                  label={
                    <Typography.Text className="normal-12 text-uppercase gray-6">
                      {gettext('TIME BETWEEN TRUCKS')}
                    </Typography.Text>
                  }
                  name={'timeBetweenTrucks'}
                  normalize={item => item?.value}
                >
                  <Select
                    disabled={(!canSetupTrucks && !isMultyProduct) || isQuote}
                    labelInValue
                    options={timeBetweenTrucksOptions}
                    onChange={item => {
                      const firstTruckTime = form.getFieldValue(['trucks', 0, 'deliveryTime']) as Moment;
                      form.setFieldValue(
                        'trucks',
                        (form.getFieldValue('trucks') as FormState['trucks']).map((truck, index) => {
                          return {
                            ...truck,
                            deliveryTime: moment.tz(firstTruckTime, order?.timezone as string).add(item.value * index, 'minutes')
                          };
                        })
                      );
                    }}
                  />
                </Form.Item>
              </DriverTruckContainer>
            </FormRow>
            <Form.List name="trucks">
              {(fields, { add, remove }) => {
                const trucksQuantityProps = orderPreparatoryConfig?.trucksConfigs.find(
                  trucksConfig => trucksConfig.deliveryMethod.name === inputProps.deliveryMethod
                )?.quantity;
                if (loading) return null;
                if (!trucksQuantityProps) {
                  return <Typography.Text type="danger">{i18n.t('labels.somethingWrong')}</Typography.Text>;
                }
                return (
                  <Space direction="vertical" className="w-100" size={12}>
                    {!!fields.length && (
                      <div>
                        <Header isMultyProduct={isMultyProduct}>
                          <Typography.Text className="bold-18 green-6">
                            {inputProps.quantity || '0'} {order?.units ?? ''}
                          </Typography.Text>

                          {isMultyProduct ? (
                            <Tabs
                              items={[
                                { key: 'general', label: gettext('General truck config') },
                                { key: 'product', label: gettext('Product config') }
                              ]}
                              activeKey={truckConfigView}
                              onChange={(vl: string) => setTruckConfigView(vl as 'product' | 'general')}
                            />
                          ) : null}
                        </Header>
                        <FormRow>
                          <MainInfoHead>
                            <Typography.Text className="normal-12 text-uppercase gray-6">{gettext('№')}</Typography.Text>
                            <Typography.Text className="normal-12 text-uppercase gray-6">{gettext('QTY')}</Typography.Text>
                          </MainInfoHead>
                          {truckConfigView === 'general' ? (
                            <DriverTruckContainer style={{ marginBottom: 0 }}>
                              <Typography.Text className="normal-12 text-uppercase gray-6">
                                {gettext('Delivery time')}
                              </Typography.Text>
                              <Typography.Text className="normal-12 text-uppercase gray-6">
                                {gettext('From location')}
                              </Typography.Text>
                              <Typography.Text className="normal-12 text-uppercase gray-6">{gettext('Truck')}</Typography.Text>
                              <Typography.Text className="normal-12 text-uppercase gray-6">{gettext('Driver')}</Typography.Text>
                            </DriverTruckContainer>
                          ) : (
                            <ProductsHeader>
                              <Typography.Text className="normal-12 text-uppercase gray-6 mb-0">
                                {gettext('Truck`s Products')}
                              </Typography.Text>
                              <Typography.Text className="normal-12 text-uppercase gray-6 mb-0">
                                {gettext('Unassigned Products')}
                              </Typography.Text>
                            </ProductsHeader>
                          )}
                        </FormRow>
                      </div>
                    )}
                    <Wrapper>
                      <div>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <FormRow key={`field_${key}`} data-testid="trucks-row" style={{ marginBottom: '12px' }}>
                            <Form.Item {...restField} name={[name, 'truckId']} hidden>
                              <Input />
                            </Form.Item>
                            <TruckRow>
                              <Form.Item {...restField} name={[name, 'value']} className="mb-0">
                                <TruckItem
                                  index={index + 1}
                                  {...trucksQuantityProps}
                                  disabled={!canSetupTrucks}
                                  inputElememt={isMultyProduct ? <></> : undefined}
                                />
                              </Form.Item>
                              {isMultyProduct ? (
                                <Form.Item {...restField} name={[name, 'deliveryItems']} className="mb-0">
                                  <Delivery />
                                </Form.Item>
                              ) : null}
                            </TruckRow>
                            {truckConfigView === 'general' ? (
                              <div>
                                <DriverTruckContainer>
                                  <Form.Item {...restField} name={[name, 'deliveryTime']} className="mb-0">
                                    <TimePicker
                                      className="w-100"
                                      minuteStep={15}
                                      showSecond={false}
                                      format={'hh:mm A'}
                                      showNow={false}
                                      allowClear={false}
                                      use12Hours
                                      disabled={fields.length === 1 && order?.deliveryWindow === 0}
                                      placeholder={gettext('Delivery time')}
                                    />
                                  </Form.Item>
                                  <Form.Item {...restField} name={[name, 'plant']} className="mb-0">
                                    <AsyncQuerySelect
                                      defaultValue={orderPlantItem}
                                      disabled={isQuote}
                                      allowClear
                                      query={(name: string) => ({
                                        fetchPolicy: 'no-cache',
                                        query: plantsQuery,
                                        variables: {
                                          name,
                                          ...makeIdInput('partner', order?.partner?.id)
                                        }
                                      })}
                                      fetchOptions={data =>
                                        data?.plants.values.map(({ id, name }: any) => ({ label: name, value: id }))
                                      }
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'truck']}
                                    className="mb-0"
                                    rules={[
                                      {
                                        validator: async (rule, value) => {
                                          const selectedDriver = form.getFieldValue(['trucks', name, 'driver']);
                                          if (selectedDriver && !value) {
                                            return Promise.reject(gettext('Required'));
                                          }
                                          return Promise.resolve();
                                        }
                                      }
                                    ]}
                                  >
                                    <AsyncQuerySelect
                                      key={getSelectedPlantId(name)}
                                      disabled={isQuote}
                                      onSelect={(_: any, option: any) => onTruckSelect(option, name)}
                                      query={(search: string) => ({
                                        fetchPolicy: 'no-cache',
                                        query: trucksQuery,
                                        variables: {
                                          search,
                                          ...makeIdInput('plant', getSelectedPlantId(name)),
                                          ...(!isEmpty(possibleDeliveryTruckRoles) && { roles: possibleDeliveryTruckRoles })
                                        }
                                      })}
                                      fetchOptions={data => {
                                        return data?.trucks.values.map((truck: Truck) => ({
                                          label: truck.name || '',
                                          value: truck.id || '',
                                          data: truck
                                        }));
                                      }}
                                    />
                                  </Form.Item>
                                  <Form.Item {...restField} name={[name, 'driver']} className="mb-0">
                                    <AsyncQuerySelect
                                      key={getSelectedPlantId(name) + driverInputKey}
                                      allowClear
                                      disabled={isQuote}
                                      onSelect={(_: any, option: any) => onDriverSelect(option, name)}
                                      query={(search: string) => ({
                                        fetchPolicy: 'no-cache',
                                        query: driversQuery,
                                        variables: {
                                          search,
                                          ...makeIdInput('plant', getSelectedPlantId(name)),
                                          ...(!isEmpty(possibleDeliveryDriverRoles) && { roles: possibleDeliveryDriverRoles })
                                        }
                                      })}
                                      fetchOptions={data =>
                                        data?.drivers.values.map((driver: Driver) => ({
                                          label: driver.info?.name,
                                          value: driver.id,
                                          data: driver
                                        }))
                                      }
                                    />
                                  </Form.Item>

                                  <Form.Item className="mb-0">
                                    <Tooltip title={gettext('Delete Truck')}>
                                      <Button
                                        type="text"
                                        style={{ width: 'fit-content' }}
                                        disabled={(!canSetupTrucks && !isMultyProduct) || fields.length === 1}
                                        icon={
                                          <DeleteFilled
                                            className={`${
                                              (!canSetupTrucks && !isMultyProduct) || fields.length === 1 ? '' : 'gray-7'
                                            }`}
                                          />
                                        }
                                        onClick={() => {
                                          if (isMultyProduct) {
                                            const value = form.getFieldValue(['trucks', name, 'deliveryItems']);
                                            if (!Array.isArray(value)) return remove(name);
                                            const newValue = value.reduce((res, item) => {
                                              return mergeData(item, res);
                                            }, form.getFieldValue('unisignedProducts'));
                                            form.setFields([
                                              {
                                                name: 'unisignedProducts',
                                                value: newValue,
                                                touched: true
                                              }
                                            ]);
                                          }

                                          remove(name);
                                          form.setFieldsValue({});
                                        }}
                                      />
                                    </Tooltip>
                                  </Form.Item>
                                </DriverTruckContainer>
                              </div>
                            ) : (
                              <ProductsFormItem {...restField} name={[name, 'deliveryItems']}>
                                <Products index={name} remove={() => remove(name)} />
                              </ProductsFormItem>
                            )}
                          </FormRow>
                        ))}
                      </div>
                      <div id={'uninusigned'}></div>
                    </Wrapper>
                    <div className="d-flex justify-content-end align-middle">
                      <Button
                        disabled={!canSetupTrucks && !isMultyProduct}
                        type="link"
                        icon={<img src={ICONS.icTruckContainer} alt="Add truck" className="mr-2" />}
                        className="p-0 h-auto"
                        onClick={() => {
                          const trucks = form.getFieldValue(['trucks']) as { deliveryTime: Moment }[];
                          const timeBetweenTrucks = (form.getFieldValue(['timeBetweenTrucks']) as number) || 0;
                          add({
                            value: trucksQuantityProps.min,
                            deliveryTime: trucks.length
                              ? moment
                                  .tz(trucks[trucks.length - 1].deliveryTime, order?.timezone as string)
                                  .add(timeBetweenTrucks, 'minutes')
                              : moment.tz(order?.deliveryTime, order?.timezone as string)
                          });
                        }}
                      >
                        {gettext('Add truck')}
                      </Button>
                    </div>
                  </Space>
                );
              }}
            </Form.List>

            {document.getElementById('uninusigned')
              ? truckConfigView === 'product' &&
                createPortal(
                  <UnisignedFormItem
                    name={'unisignedProducts'}
                    rules={[
                      {
                        validator: async (rule, value) => {
                          if (value && Array.isArray(value) && value.filter(({ quantity }) => quantity).length) {
                            return Promise.reject(gettext('Please assign all Products to the trucks'));
                          }
                          return Promise.resolve();
                        }
                      }
                    ]}
                  >
                    <UnisignedProducts errors={form.getFieldError(['unisignedProducts'])} />
                  </UnisignedFormItem>,
                  document.getElementById('uninusigned') as Element
                )
              : null}

            <OrderFeePanel orderId={orderId} input={feeInput} instance={feeInstance} onLoading={setFeesLoading} />
          </DNDConfirantion>
        </Form>
      </Spin>
    </Modal>
  );
};

const MainInfoHead = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: 150px;
  > span {
    flex: 1;
  }
`;
const Header = styled.div<{ isMultyProduct?: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMultyProduct }) => (isMultyProduct ? '150px' : '2fr')} 1fr;
  gap: 24px;
  align-items: center;
  margin-bottom: 32px;
  border-bottom: ${({ isMultyProduct }) => (isMultyProduct ? '1px solid var(--gray-0)' : 'none')};
  .ant-tabs-nav {
    margin: 0 !important;
    :before {
      content: none;
    }
    .ant-tabs-nav-wrap {
      overflow: visible !important;
    }
    .ant-tabs-ink-bar-animated {
      bottom: -1px;
    }
    .ant-tabs-tab-btn {
      line-height: 26px;
    }
  }
  .ant-tabs-tab {
    padding-top: 0 !important;
    padding-bottom: 0px !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  > div {
    flex: 1;
  }
  #uninusigned {
    flex: 0;
    display: flex;
    flex-direction: column;
  }
`;

const ProductsHeader = styled.div`
  display: flex;
  align-items: center;

  > span {
    flex: 1;
    &:last-of-type {
      max-width: 168px;
      min-width: 168px;
      padding-left: 12px;
    }
  }
`;

const ProductsFormItem = styled(Form.Item)`
  flex: 2;
  margin-bottom: 0 !important;
  .ant-form-item-control-input {
    flex-direction: column !important;
  }
  .ant-form-item-control-input-content {
    flex: 1;
    width: 100%;
    display: flex;
  }
`;

const TruckRow = styled.div`
  display: flex;
  > div {
    flex: 1;
  }
  align-items: center;
  gap: 12px;
  .ant-form-item-control-input {
    min-height: 0;
  }
`;

const QTY = styled.div`
  > span:last-of-type {
    margin-left: 4px;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UnisignedFormItem = styled(Form.Item)`
  margin-bottom: 0 !important;
  flex: 1 !important;
  display: flex !important;
  .ant-form-item-control-input,
  .ant-form-item-control-input-content {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
  }
`;
